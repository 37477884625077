<template>
  <div class="payments-complete">
    <div class="content">
      <img src="../assets/icon/ico-successful.svg" alt="" />
      <p class="text-success">
        {{tip1}}
      </p>
      <p class="text-receive">
        {{title}}
      </p>
    </div>
  </div>
</template>
<script>
import { apiSendNotification } from "../API/api";
export default {
  data() {
    return {
      tip1:"Thank you for notifying the tenant to make payments!",
      title:"The email was successfully sent to the tenant, thank you for the notice.",
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      apiSendNotification({ lease: this.$route.query.lease }).then((res) => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.payments-complete {
  width: 100%;
  .content {
    margin: 0 auto;
    margin-top: 287px;
    // width: 387px;
    height: 210px;
    .text-success,
    .text-receive {
      font-size: 20px;
      font-family: "Roboto-Regular", sans-serif;
      color: #38425b;
      line-height: 30px;
    }
    .text-success {
      margin-top: 30px;
      font-family: "Roboto-Bold", sans-serif;
    }
  }
}
</style>